<template>
    <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
        <template #header>
            <view-search-form :queryInfo.sync="queryInfo" @on-search="renderTable(1)" keyword-placeholder="姓名"
                :tool-list="['keyword', 'gradeName', 'term']">
                <el-form-item label="部门" v-permission="['achievement:patent:all']">
                    <el-select v-model="queryInfo.deptId" clearable filterable size="small">
                        <el-option v-for="( item, index ) in  departmentList " :key="index" :label="item.deptName"
                            :value="item.id" />
                    </el-select>
                </el-form-item>
            </view-search-form>
            <div class="header-button mb-1">
                <el-tag class="successNum">专利总数:{{ count }}</el-tag>
                <el-button @click="exportExcel()" type="success" size="small">
                    专利成果导出
                </el-button>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column type='index' label='ID' width='50' />
            <el-table-column prop="realName" label="姓名" width="70"></el-table-column>
            <el-table-column prop="deptName" label="部门"></el-table-column>
            <el-table-column prop="teacherNo" label="工号"></el-table-column>
            <el-table-column prop="gradeName" min-width="100" label="学年"></el-table-column>
            <el-table-column prop="term" label="学期">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term}学期` : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="patentName" label="专利名"></el-table-column>
            <el-table-column prop="patentNo" label="专利号"></el-table-column>
            <el-table-column prop="applyDate" min-width="100px" label="申请时间"></el-table-column>
            <el-table-column prop="grantDate" min-width="100px" label="授权时间"></el-table-column>
            <el-table-column label="操作" min-width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row.id)">
                        查看详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialog-patent ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog' @on-close='renderTable' />
    </table-view>
</template>

<script>
import { pagePatentApi, delPatentApi, countPatentApi } from '@/api/achievement/patent-api.js'
import dialogPatent from './components/DialogPatent.vue'
import tableView from '@/vue/mixins/table-view'
import store from '@/store/index'

export default {
    mixins: [tableView],
    components: { dialogPatent },
    data() {
        return {
            orderType: 'teacher', // 人员类型
            queryInfo: {
                startDate: null,
                endDate: null,
                deptId: null,
                gradeName: null,
                term: null
            },
            departmentList: '',
            formData: {
                id: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                term: '',
                patentName: '',
                applyDate: '',
                grantDate: '',
                patentNo: ''
            },
            count: {},
            rules: {
                patentName: [this.$store.state.commonValidate.limit30WordsObj],
                patentNo: [this.$store.state.commonValidate.validatorNumberObj]
            }
        }
    },
    mounted() {
        this.renderTable()
    },
    methods: {
        delPatentApi,
        async renderTable(pageNum) {
            this.getAll()
            this.countPatentApi()
            this.getDepartment()

            await this.getTableData(pagePatentApi, pageNum)
        },
        countPatentApi() {
            countPatentApi(this.queryInfo).then((res) => {
                this.count = res.data
            })
        },
        // 查看全部的权限
        getAll() {
            const _permission = store.getters['login/getPermission']
            if (!(_permission.includes('achievement:patent:all'))) {
                this.queryInfo.deptId = this.$store.state.login.userInfo.deptId
            }
        },
        // 获取部门
        getDepartment() {
            this.$http.getDepartmentListQuery({}).then((res) => {
                this.departmentList = res.data
            })
        },
        // 导出
        exportExcel() {
            this.$http.exportExcelHttp.exportPatent(this.queryInfo)
        }

    }

}
</script>

<style lang="scss" scoped>
.successNum {
    margin-right: 20px;
}
</style>
