<template>
    <el-dialog class='grade-dialog' :title='`${preTitle}专利`' :visible.sync='show' :close-on-click-modal='false'
        width='1000px' :before-close='cancel'>
        <el-form ref='form' :model='formData' :rules='rules' label-width='120px'>
            <el-divider content-position="left">
                <h3>个人信息</h3>
            </el-divider>
            <el-row>
                <el-col :span='16'>
                    <el-form-item label="姓名：" prop="userId">
                        {{ formData.realName }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='部门：' prop='deptName'>
                        {{ formData.deptName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='工号：' prop='teacherNo'>
                        {{ formData.teacherNo }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider content-position="left">
                <h3>专利信息</h3>
            </el-divider>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='学年：' prop='gradeName'>
                        {{ formData.gradeName }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='学期：' prop='term'>
                        第{{ formData.term }}学期
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='申请时间：' prop='applyDate'>
                        {{ formData.applyDate }}
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='授权时间：' prop='grantDate'>
                        {{ formData.grantDate }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span='8'>
                    <el-form-item label='专利名：' prop='patentName'>
                        {{ formData.patentName }}
                    </el-form-item>

                </el-col>
                <el-col :span='8'>
                    <el-form-item label='专利号：' prop='patentNo'>
                        {{ formData.patentNo }}
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'

import { getPatentByIdApi } from '@/api/achievement/patent-api.js'

export default {
    name: 'gradeDialog',
    mixins: [dialog],
    data() {
        return {
            id: '',
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptId: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                patentName: '',
                applyDate: '',
                grantDate: '',
                patentNo: '',
                userType: 3
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.id) {
                this.getDataById(this.id, getPatentByIdApi)
            } else {
            }
        })
    },
    methods: {
    }
}
</script>
